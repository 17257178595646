import { Box,Text} from '@chakra-ui/react'
import React from 'react'

const About = () => {


return (
    <Box class="about-section">
        
    </Box>
  )
}

export default About