import './App.css';
import React, { useEffect } from 'react';
import AllRoute from './Routes/AllRoutes';
import ScrollToTop from './Components/ScrollToTop'


function App() {

  return (
    <div>
       <ScrollToTop />
     <AllRoute/>
    </div>
    
  );
}

export default App;
